.apexcharts-xcrosshairs {
  mask-image: linear-gradient(transparent, black 21.2963%, transparent);
  background: rgb(255, 255, 255);
}

.apexcharts-series-markers {
  opacity: 0;
}

.apexcharts-tooltip-marker {
  display: none;
}

.apexcharts-tooltip-text {
  width: 100% !important;
}

.apexcharts-tooltip-y-group {
  display: flex !important;
  justify-content: space-between !important; /* Align items to the extreme ends */
}

.apexcharts-legend {
  top: 0 !important;
}

.apexcharts-legend-text {
  font-size: 14px !important;
  color: rgb(134, 143, 151) !important;
}

.apexcharts-legend-series {
  margin: 2px 15px !important;
}

.apexcharts-legend-marker {
  margin-right: 7px !important;
  height: 10px !important;
  width: 10px !important;
}

.apexcharts-tooltip {
  top: 0 !important;
  border: none !important;
  border-bottom: none !important;
  z-index: 1 !important;
  position: absolute !important;
  font-size: 14px !important;
  -webkit-box-align: center !important;
  background: rgba(0, 0, 0, 0.9) !important;
  backdrop-filter: blur(3px) !important;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.13) !important;
  border-radius: 7px !important;
  padding: 5px 12px !important;
  white-space: nowrap !important;
  pointer-events: none !important;
  color: rgb(255, 255, 255) !important;
  padding: 0 10px !important;
  /* display: flex !important;
  flex-direction: row !important;
  ;
   */
}

.apexcharts-tooltip-title {
  background: none !important;
  border-bottom: none !important;
}

.intervals {
  border-top: 2px solid rgba(255, 255, 255, 0.06);
}
