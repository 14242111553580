@tailwind base;
@tailwind components;
@tailwind utilities;
/* roboto-fonts.css */

@font-face {
  font-family: "Roboto-Black";
  src: url("../../public/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("../../public/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../../public/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../../public/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("../../public/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../../public/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../public/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("../../public/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../public/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../../public/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("../../public/fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
